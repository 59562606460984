import React from 'react'
import { Text, Heading, Container, Stack, Divider } from '@chakra-ui/react'
import { Link, graphql } from 'gatsby'
import BaseLayout from '../../../layout/base'
import SEO from '../../../components/SEO/PageSEO'

const Page = ({ location, data }) => (
  <BaseLayout>
    <Container maxW="5xl">
      <SEO
        title="Generador de nombres y apellidos por país"
        titleSeo="Generador de nombres y apellidos por país"
        description="Herramienta para generar nombres y apellidos al azar, populares en países hispanohablantes."
        image={data.file.childImageSharp.gatsbyImageData.images.fallback.src}
        // lang="es"
        datePublished="2021-05-11T12:29:33-03:00"
        dateModified="2021-05-11T12:29:33-03:00"
        breadcrumbs={[{ name: 'Nombres', url: '/nombres/' }]}
        permalink={location.pathname}
        // breadcrumbList={[]}
        // noindex={false}
      />
      <Stack
        textAlign="center"
        align="center"
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
      >
        <Heading
          fontWeight={600}
          fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
          lineHeight="110%"
          as="span"
        >
          <Text as="span" color="orange.400">
            Generadores de
          </Text>{' '}
          nombres y apellidos
        </Heading>
        <Heading as="h2" color="gray.500" size="md" fontWeight={500}>
          Generar nombres y apellidos por país aleatorios
        </Heading>
        <Divider />
        <Link to="/nombres/pais/espana/">España</Link>
        <Link to="/nombres/pais/mexico/">México</Link>
        <Link to="/nombres/pais/argentina/">Argentina</Link>
      </Stack>
    </Container>
  </BaseLayout>
)

export default Page

export const pageQuery = graphql`
  query {
    file(relativePath: { regex: "/extreme-5497194_1920-photopea.png/" }) {
      childImageSharp {
        gatsbyImageData(quality: 40, width: 800)
      }
    }
  }
`
